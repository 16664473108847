<template>
  <div class="app-layout" v-if="$store.state.outlet.outletId">
    <update-app v-if="updateExists" :refresh="refreshApp" />
    <navbar v-if="isNotAdminPanel" />
    <div>
      <router-view v-slot="{ Component }">
        <transition name="slide-fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <notifications position="bottom left" group="info" class="nt info" />
    <notifications position="top right" group="warning" class="nt warning" />
    <notifications position="top right" group="error" class="nt danger" />
  </div>
  <div v-else>
    <outlet-selector @app-init="appInit" />
  </div>
</template>
<script>
import update from "@/mixins/update";
import OutletSelector from "../views/OutletSelector.vue";
import Navbar from "@/components/Navbar.vue";
import UpdateApp from "@/components/UpdateApp.vue";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

export default {
  components: {
    Navbar,
    OutletSelector,
    UpdateApp,
  },
  data() {
    return {
      connection: null,
      online: false,
    };
  },

  mixins: [update],
  watch: {},
  computed: {
    isNotAdminPanel() {
      var route = this.$route;
      return route.matched[1].name === "Admin Panel" ? false : true;
    },
  },
  methods: {
    billUpdated(id) {},
  },
  mounted: function () {
    window.addEventListener("offline", function () {
      this.online = false;
    });

    window.addEventListener("online", function () {
      this.online = true;
    });
  },
  created() {
    var token = this.$store.getters.getUser?.token;

    this.connection = new HubConnectionBuilder()
      .withUrl(
        process.env.NODE_ENV === "development"
          ? "https://localhost:5001/notificationHub"
          : "https://cuoco.azurewebsites.net/notificationHub",
        { accessTokenFactory: () => token }
      )
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();
    this.connection.on("SendAsync", async (payload) => {
      this.$store.dispatch("NOTIFICATION_RECEIVED", payload);
      var bill = await this.$api.getBill(payload.id);

      this.$store.dispatch("UPDATE_BILL", bill.data);

      this.$notify({
        group: payload.type,
        title: payload.title ?? "Notification",
        text: payload.message,
        duration: 2000,
        speed: 1000,
      });
    });

    this.connection.on("CheckoutAsync", async (payload) => {
      this.$store.dispatch("NOTIFICATION_RECEIVED", payload);
      var bill = await this.$api.getBill(payload.id);

      this.$store.dispatch("CHECKOUT", bill.data);

      this.$notify({
        group: payload.type,
        title: payload.title ?? "Notification",
        text: payload.message,
        duration: 2000,
        speed: 1000,
      });
    });

    // connection.on("PrintBillAsync", async (payload) => {
    //   var bill = await this.$api.getBill(payload.id);

    //   this.$notify({
    //     group: payload.type,
    //     title: payload.title ?? "Notification",
    //     text: payload.message,
    //     duration: 2000,
    //     speed: 1000,
    //   });
    // });

    this.connection.start();
  },
  unmounted() {
    this.connection = null;
  },
};
</script>
<style lang="scss">
.discount-input {
  border-radius: 5px;
  width: 50px;
  border: none;
  outline: none;
  width: 50px;
  text-align: end;
  margin: 0;
  padding-right: 0;
}
</style>
