import http from "axios";
import store from "@/store";
import router from "@/router";
http.interceptors.request.use(
  function (config) {
    config.baseURL =
      process.env.NODE_ENV === "development"
        ? "https://localhost:5001/api"
        : "https://cuoco.azurewebsites.net/api";
    const token = store.getters.getUser?.token;
    const outletId = store.getters.getOutlet?.outletId;
    var shiftId = store.getters.getShift?.shiftUUID;

    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }

    if (outletId) {
      config.headers["X-Outlet-Id"] = outletId;

      if (shiftId) {
        config.headers["X-Shift-Id"] = shiftId;
      }
    }

    store.dispatch("REST_CALL", true);
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

http.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      store.dispatch("REST_CALL", false);
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    console.log(error.response);
    switch (error.response?.status) {
      case 401:
        store.dispatch("NOTIFICATION_RECEIVED", {
          title: "Authentication failed",
          message: "Email or password provided is incorrect",
          type: "error",
        });
        router.replace({
          path: "/auth/login",
          query: { redirect: router.currentRoute.fullPath },
        });
        break;
      case 403:
        store.dispatch("NOTIFICATION_RECEIVED", {
          title: "Authorization failed",
          message: "You are not authorized to view this resource.",
          type: "warn",
        });
        router.replace({
          path: "/auth/login",
          query: { redirect: router.currentRoute.fullPath },
        });
        break;
      // case 404:
      //   return Promise.reject({
      //     code: 404,
      //     succeeded: false,
      //     message: "Not Found",
      //     type: "warn",
      //   });
      //   break;
      case 400:
        store.dispatch("NOTIFICATION_RECEIVED", {
          title: "Error occured",
          message: error?.response?.data?.message,
          type: "error",
        });

        console.log(error.response.data);

        return Promise.resolve(error.response.data);
        break;
      default:
        store.dispatch("NOTIFICATION_RECEIVED", {
          title: "Error occured",
          message: error?.response?.data?.message,
          type: "error",
        });
        break;
    }

    return Promise.reject(error);
  }
);

class RestApiService {
  async changePassword(payload) {
    return await http.put("auth/changePassword", {
      ...payload,
      confirmPassword: payload.newPassword,
    });
  }
  async updateDisplayName(payload) {
    return await http.put("auth/displayName", payload);
  }
  async getAsync(url) {
    return await http.get(url);
  }

  createAsync(url, payload, type = "json") {
    if (type === "form") {
      var form_data = new FormData();

      for (var key in payload) {
        form_data.append(key, payload[key]);
      }

      return http.post(url, form_data);
    }

    return http.post(url, payload);
  }

  async updateAsync(url, payload, type) {
    if (type === "form") {
      var form_data = new FormData();

      for (var key in payload) {
        form_data.append(key, payload[key]);
      }

      return await http.put(url, form_data);
    }

    return await http.put(url, payload);
  }

  async deleteAsync(url, payload) {
    return await http.delete(url + "/" + payload);
  }

  openShift(payload) {
    return http.put("bis/shift/open", payload);
  }
  endShift(payload) {
    return http.put("bis/shift/end", payload);
  }
  initApp(outletId) {
    return http.get("/app/" + outletId);
  }
  init(system) {
    return http.get(system + "/init");
  }

  getCustomerByPhone(phone) {
    return http.get("crm/customers/phone/" + phone);
  }
  getCustomers(page, size) {
    return http.get("crm/all?pageIndex=" + page + "&pageSize=" + size);
  }
  addNewCustomer(payload) {
    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    return http.post("crm/customer/create", form_data, {
      "Content-Type": "multipart/form-data",
    });
  }
  updateCustomer(payload) {
    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    return http.post("/crm/customer/update", form_data, {
      "Content-Type": "multipart/form-data",
    });
  }
  deleteCustomer(id) {
    return http.delete("crm/customer/delete/" + id);
  }
  getVendors(page, size) {
    return http.get("ims/vendors/all?pageIndex=" + page + "&pageSize=" + size);
  }
  getRequests(page, size) {
    return http.get("ims/requests/all?pageIndex=" + page + "&pageSize=" + size);
  }
  createRequest(request) {
    request.id = 0;
    return http.post("ims/requests/create", request);
  }
  createVendor(vendor) {
    return http.post("ims/vendors/create", vendor);
  }
  updateVendor(vendor) {
    return http.put("ims/vendors/update", vendor);
  }
  deleteVendor(id) {
    return http.delete("ims/vendors/" + id + "/delete");
  }

  printBill(payload) {
    return http.post("http://localhost:14141/print/bill", payload);
  }
  printTicket(payload) {
    return http.post("http://localhost:14141/print/ticket", payload);
  }
  login(payload) {
    return http.post("auth/login", payload);
  }
  forgot(payload) {
    return http.post("auth/forgot", payload);
  }
  fetchMenu() {
    return http.get("bis/menu/get");
  }
  fetchActiveBills() {
    return http.get("bis/bills/get/active");
  }
  historyToday() {
    return http.get("bis/bills/today");
  }
  getBill(id) {
    return http.get("bis/bills/find/" + id);
  }
  getShiftInfo() {
    return http.get("bis/shift/info");
  }
  createBill(bill) {
    return http.post("bis/bill/create", bill);
  }
  updateBill(bill) {
    return http.put("bis/bill/update", bill);
  }
  sendBill(bill) {
    return http.put("bis/bill/send/" + bill.id);
  }
  voidBill(payload) {
    return http.put("bis/bill/void", payload);
  }
  checkout(id, payload) {
    return http.put("bis/bill/" + id + "/pay", payload);
  }
  createCategory(payload) {
    return http.post("bis/menu/category/create", payload);
  }
  deleteCategory(id) {
    return http.delete("bis/menu/category/" + id);
  }
  createMenu(payload) {
    return http.post("bis/menu/create", payload);
  }
  updateMenuItem(payload) {
    return http.post("bis/menu/item/update", payload, {
      "Content-Type": "multipart/form-data",
    });
  }
  addMenuItem(payload) {
    return http.post("bis/menu/items/add", payload, {
      "Content-Type": "multipart/form-data",
    });
  }
  deleteMenuItem(id) {
    return http.delete("bis/menu/item/" + id + "/delete");
  }
  deleteMenu(id) {
    return http.delete("bis/menu/" + id + "/delete");
  }
  fetchBills(page, size) {
    var baseUrl = "bis/bills/get/all?";

    if (page) {
      baseUrl += "page=" + page;
    }

    if (size) {
      baseUrl += "&size=" + size;
    }

    return http.get(baseUrl);
  }

  fetchInventory(page, size) {
    return http.get("ims/all?pageIndex=" + page + "&pageSize=" + size);
  }
  fetchOutlets() {
    return http.get("outlet");
  }

  getInventory(id) {
    return http.get("ims/items/find/" + id);
  }
  createInventory(payload) {
    return http.post("ims/items/create", payload);
  }
  updateInventory(payload) {
    return http.put("ims/items/update", payload);
  }
  deleteInventoryItem(id) {
    return http.delete("ims/items/delete/" + id);
  }
}
export default new RestApiService();
