<template>
  <div class="end-shift">
    <div>
      <div v-if="info" class="row pt-4" style="margin: 0">
        <div class="col-md">
          <h6>Current Shift Information</h6>
          <div class="shift-info">
            <div class="info d-flex justify-content-between">
              <div class="current">
                <p class="label">
                  Created At:
                  <strong>
                    {{
                      moment(info.currentShift.createdAt).format(
                        "MMM DD, YYYY hh:mm:ss"
                      )
                    }}
                  </strong>
                </p>
                <p class="label">
                  Created by:
                  <strong>
                    {{ info.currentShift.createdBy }}
                  </strong>
                </p>
                <p class="label">
                  Status:
                  <strong>
                    {{ info.currentShift.isOpen ? "Open" : "Closed" }}
                  </strong>
                </p>
              </div>
            </div>
            <hr />
            <div class="totals">
              <h6>Today's Sales Summary</h6>
              <table class="table">
                <thead>
                  <tr>
                    <td>Cash Sales</td>
                    <td class="text-end">
                      <strong>{{ info.cashSales?.toFixed(2) }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Card Sales</td>
                    <td class="text-end">
                      <strong>{{ info.cardSales?.toFixed(2) }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>BML Transfers</td>
                    <td class="text-end">
                      <strong>{{ info.bmlTransfers?.toFixed(2) }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Mobile Pay</td>
                    <td class="text-end">
                      <strong>{{ info.mobilePay?.toFixed(2) }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Account Sales</td>
                    <td class="text-end">
                      <strong>{{ info.accountSales?.toFixed(2) }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Void Sales</td>
                    <td class="text-end">
                      <strong>{{ info.voidSales?.toFixed(2) }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Total Sales</td>
                    <td class="text-end">
                      <strong>{{ info.totalSales?.toFixed(2) }}</strong>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
            <hr />
            <div class="results">
              <table class="table">
                <thead>
                  <tr>
                    <td>Opening Balance</td>
                    <td class="text-end">
                      {{ info.currentShift.openingBalance?.toFixed(2) }}
                    </td>
                  </tr>
                  <tr>
                    <td>Cash Drawer</td>
                    <td class="text-end">{{ total?.toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td>Difference</td>
                    <td class="text-end">
                      <strong
                        :class="difference < 0 ? 'text-danger' : 'text-success'"
                      >
                        {{ difference?.toFixed(2) }}</strong
                      >
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="d-flex">
            <div class="cash-counter opening" v-if="this.shift?.openingPayload">
              <h6>Cash drawer - Opening</h6>
              <table class="table cash-counter-table table-borderless">
                <tbody>
                  <tr
                    v-for="(item, index) in Object.keys(getOpeningDrawer)"
                    :key="index"
                  >
                    <td>
                      <p>{{ getOpeningDrawer[item].label }}</p>
                    </td>
                    <td>
                      <input
                        class="text"
                        type="text"
                        v-model="getOpeningDrawer[item].qty"
                        placeholder="500"
                        disabled
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="cash-counter closing">
              <h6>Cash drawer - Closing</h6>
              <table class="table cash-counter-table table-borderless">
                <tbody>
                  <tr v-for="note in notes" :key="note">
                    <td>
                      <p>{{ note.label }}</p>
                    </td>
                    <td>
                      <input
                        class="text"
                        type="text"
                        v-model="note.qty"
                        placeholder="500"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div></div>
        <div class="d-flex w-25">
          <button
            @click="endShift"
            :disabled="loadingend"
            class="btn primary mt-4"
          >
            <span v-if="!loadingend">End shift</span>
            <span
              v-else
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div v-show="loading" class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      loading: false,
      loadingend: false,
      shift: this.$store.state.bis.shift,
      info: null,
      notes: {
        "1000 x": { label: "1000", amount: 1000, qty: 0 },
        "500 x": { label: "500", amount: 500, qty: 0 },
        "100 x": { label: "100", amount: 100, qty: 0 },
        "50 x": { label: "50", amount: 50, qty: 0 },
        "20 x": { label: "20", amount: 20, qty: 0 },
        "10 x": { label: "10", amount: 10, qty: 0 },
        "5 x": { label: "5", amount: 5, qty: 0 },
        Coins: { label: "Coins", amount: 1, qty: 0 },
      },
    };
  },
  computed: {
    getOpeningDrawer() {
      var payload = this.shift?.openingPayload;

      if (!payload) {
        return;
      }
      var json = JSON.parse(payload);
      return json;
    },
    total() {
      let total = 0;
      Object.keys(this.notes).forEach((item) => {
        total += this.notes[item].amount * this.notes[item].qty;
      });

      return total;
    },
    difference() {
      const total = this.total;
      const openingBalance = this.info.currentShift?.openingBalance;
      return total === null
        ? 0
        : total - (this.info.cashSales + openingBalance);
    },
  },
  methods: {
    endShift() {
      this.loadingend = true;

      var payload = {
        shiftId: this.$store.state.bis.shift.id,
        cashdrawerBalance: this.total,
        payload: JSON.stringify(this.notes),
      };

      this.$api.endShift(payload).then(
        (response) => {
          (this.loadingend = false),
            this.$store.commit("END_SHIFT", response.data);
        },
        (error) => {
          this.$notify({
            group: "error",
            title: "Error while ending current shift",
            text: error.response?.data?.message,
            type: "error",
            duration: 2000,
            speed: 1000,
          });
        }
      );
    },
  },
  mounted: function () {
    this.moment = moment;
    this.loading = true;
    this.$api.getShiftInfo().then(
      (response) => {
        this.info = response.data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.$notify({
          group: "error",
          title: "Error while getting shift info",
          text: error.response?.data?.message,
          type: "error",
          duration: 2000,
          speed: 1000,
        });
      }
    );
  },
};
</script>
<style lang="scss" scoped>
.end-shift {
  min-width: 75vw;
  min-height: 50vh;
  position: relative;
}

.text {
  height: 30px !important;
  width: 150px;
}
table {
  border-spacing: 0;
  td,
  tr {
    padding: 0px 0;
    border: none !important;
    color: #707070;
  }
}
.cash-counter {
  border-left: 1px solid #dfdfdf;
  padding: 10px;
}
</style>
