<template>
  <div class="admin-layout container-fluid">
    <div class="row">
      <div class="admin-sidebar"></div>
      <div class="col-md bg-white">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
<style lang="scss" scoped>
.admin-sidebar {
  width: 70px;
  height: 100vh !important;
  background: black;
}
</style>
