<template>
  <div class="account-edit">
    <div class="d-flex">
      <div class="pp">
        <img
          v-if="user.avt"
          :src="user.avt"
          class="avatar rounded-circle"
          height="150"
          loading="lazy"
        />
        <img
          v-else
          src="@/assets/avatar.png"
          class="rounded-circle"
          height="150"
          loading="lazy"
        />
        <p class="label text-center">Upload a profile pic</p>
        <div class="pp-info mt-3">
          <p class="label">
            <strong>Joined: </strong
            >{{ moment(user.joinedDate).format("MMM DD, YYYY") }}
          </p>
          <p class="label"><strong>Employee Id: </strong>{{ user.emp }}</p>
          <p class="label"><strong>Department: </strong>{{ user.dep }}</p>
          <p class="label"><strong>Job title: </strong>{{ user.job }}</p>
        </div>
      </div>
      <div class="form mt-5" style="padding-left: 10px; width: 100%">
        <p class="label">Display name</p>
        <div class="d-flex">
          <input
            style="width: 100%"
            type="text"
            class="text"
            name="displayName"
            form="displayName"
            placeholder="Display name"
            autocomplete="false"
            v-model="profile.displayName"
          />

          <button
            @click="updateDisplayName"
            style="width: 120px; margin-left: 10px"
            class="btn primary"
          >
            Update
          </button>
        </div>
        <hr />
        <div>
          <p class="text-black mt-4">Security</p>

          <p class="label">Current password</p>
          <input
            type="password"
            class="text"
            placeholder="Current password"
            autocomplete="rutjfkddde"
            v-model="credential.currentPassword"
          />
          <p class="label">New password</p>
          <input
            type="password"
            class="text"
            placeholder="New password"
            v-model="credential.newPassword"
          />
        </div>
        <button
          @click="changePassword"
          class="btn primary mt-3"
          style="width: 180px; float: right"
        >
          Change password
        </button>
      </div>
      <!-- <button style="height: 40px; width: 150px" class="btn mx-2 primary">
        Upload
      </button>
      <button style="height: 40px; width: 150px" class="btn mx-2 secondary">
        Remove
      </button> -->
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      user: this.$store.state.user.data,
      credential: {
        currentPassword: null,
        newPassword: null,
      },
      profile: {
        displayName: this.user?.dn,
      },
    };
  },
  methods: {
    changePassword() {
      this.$api.changePassword(this.credential).then(
        (response) => {
          this.$notify({
            group: "success",
            title: "Account security update success",
            text: response?.data?.message,
            type: "error",
            duration: 2000,
            speed: 1000,
          });
        },
        () => {
          this.$notify({
            group: "error",
            title: "Account security",
            text: "Password change did not succeed",
            type: "error",
            duration: 2000,
            speed: 1000,
          });
        }
      );
    },
    async updateDisplayName() {
      console.log(this.user);
      var result = await this.$api.updateDisplayName(this.profile);
    },
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
<style lang="scss">
.avatar {
  background-size: cover;
  width: 150px;
  height: 150px;
}

.account-edit {
  width: 500px;
}
</style>
