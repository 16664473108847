<template>
  <div>
    <div class="app">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      toggle: false,
    };
  },
  watch: {
    "$store.state.user"(a) {
      if (a === null) {
        this.$router.push("/auth/login");
      }
    },
  },
  methods: {},
  created: function () {},
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100vh; /* Full height (cover the whole page) */
  top: 0;
  z-index: 9999;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: rgba(240, 240, 240, 0.5);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
}
.scroll {
  overflow-y: auto;
  position: relative;
  height: 81vh !important;
}
.btn {
  &:disabled {
    opacity: 0.5;
  }
}
.text {
  height: 40px !important;
  width: 100%;
  outline: none;
  padding: 5px 10px;
  border: 1px solid #c5c5c5;
  color: #818181;
  border-radius: 8px;
  &.open {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &:disabled {
    background: #f3f3f3;
  }
}
.mx-context-menu {
  z-index: 9999999 !important;
  .text {
    border: none !important;
    text-align: start;
    i {
      display: none !important;
    }
  }
}
.text-area {
  border-radius: 8px;
  width: 100%;
  outline: none;
  padding: 5px 10px;
  border: 1px solid #c5c5c5;
  resize: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
$primary-bg: #6e6976;
$raydius: 10px;
$primary-border: #707070;
$status-order-taken: #2186c2;
$status-order-served: #8121c2;
$status-order-taken: #c2216a;
$status-order-taken: #21c2b7;
$avatar: 38px 38px;
$font: "Roboto", sans-serif !important;
@media screen and (min-width: 992px) {
  //For Large Laptops
  body {
    // overflow: hidden !important;
  }
}
body {
  overflow: hidden;
  background: $primary-bg !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.transparent-input {
  border: 1px solid rgb(233, 233, 233);
  border-radius: 5px;
  outline: none;
  width: auto;
  text-align: start;
  resize: none;
  padding: 2px 5px;
  overflow: hidden;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.2s ease-out;
}
.slide-enter-to {
  position: absolute;
  right: 0;
}
.slide-enter-from {
  position: absolute;
  right: -100%;
}
.slide-leave-to {
  position: absolute;
  left: -100%;
}
.slide-leave-from {
  position: absolute;
  left: 0;
}
.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.85s ease;
}
.scale-slide-enter-from {
  left: -100%;
}
.scale-slide-enter-to {
  left: 0%;
}
.scale-slide-leave-from {
  transform: scale(1);
}
.scale-slide-leave-to {
  transform: scale(0.8);
}
.ps--active-y {
}
.ps {
  transition: transform 0.5s linear;
}
.ps__thumb-x {
  height: 5px !important;
}
.ps__thumb-y {
  width: 5px !important;
}
.label {
  font-size: 12px;
  margin: 0;
  color: #707070;
  text-align: start;
}

// .table {
//   tbody {
//     tr {
//       cursor: pointer;
//       &:hover {
//         background-color: rgb(255, 255, 255);
//       }
//       &.Void {
//         background-color: rgb(255, 234, 234);
//         &:hover {
//           background-color: rgb(255, 178, 178) !important;
//         }
//         &.selected {
//           background-color: rgb(255, 178, 178) !important;
//           &:hover {
//             background-color: rgb(255, 178, 178) !important;
//           }
//         }
//       }
//       &.selected {
//         background-color: rgb(219, 219, 219);
//         &:hover {
//           background-color: rgb(219, 219, 219);
//         }
//       }
//     }
//   }
// }
.ps__rail-x:hover {
  background: transparent !important;
}
.ps__rail-y:hover {
  background: transparent !important;
}
#app {
  font-family: $font;
}
.content-area {
  position: relative;
  border-radius: 12px;
  padding: 10px;
  background: white;
  transition: all 0.5s ease;
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}
.btn {
  &.new-delivery-order {
    width: 225px;
    position: absolute;
    padding: 3px;
    bottom: 2px;
    border-radius: 8px;
    margin-right: 45px;
    border: 1px solid #e47672;
    color: #e47672;
    background: #fde5e1;
  }
  &.primary {
    border-radius: 12px;
    border: 1px solid #e47672;
    color: white;
    background: #e47672;
    width: 100%;
  }
  &.secondary {
    border-radius: 12px;
    border: 1px solid #e47672;
    color: #e47672;
    width: 100%;
    background: transparent;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #e47672;
}
.spinner {
  margin: 25px;
  width: 100%;
  height: 500px;
  position: relative;
  /* Setup */
  position: relative;
  .loader {
    width: 3em;
    height: 3em;
    border: 0.2em solid rgba(215, 219, 223, 1);
    border-left: 0.3em solid #e47672;
    animation: spin 1.1s infinite linear;
    margin: -1.5em 0 0 -1.5em; /* Half this element's height */
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .loader,
  .loader:after {
    border-radius: 50%;
  }
  @keyframes spin {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}
.spinner-border {
  &.center {
    display: block;
    position: fixed;
    z-index: 1031; /* High z-index so it is on top of the page */
    top: calc(50% - (90vh / 2)); /* where ... is the element's height */
    right: calc(50% - (55vw / 2)); /* where ... is the element's width */
  }
}
.btn {
  &.action {
    margin: 0;
    padding: 0 5px;
  }
  &.filter {
    border: none;
    width: 20px;
    height: 20px;
    background: url("~@/assets/filter-results-button.png") no-repeat;
    background-size: contain;
  }
}
.tags {
  .tag {
    background: rgb(255, 115, 1);
    border-radius: 12px;
    color: white;
    padding: 2px 6px;
    font-size: 12px;
    margin-right: 10px;
    strong {
      cursor: pointer;
    }
  }
}

.vue-notification {
  border: none !important;
  color: black !important;
}

.nt {
  .notification-title {
    border-bottom: 1px solid rgba(238, 238, 238, 0.2);
    font-weight: bold;
  }

  .notification-content {
    font-weight: normal !important;
    padding: 10px 0;
  }

  display: block;
  box-sizing: border-box;
  text-align: left;
  font-size: 12px;
  padding: 10px;
  margin: 12px;
}
</style>
